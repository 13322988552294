import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import urljoin from 'url-join';
import config from '../../../SiteConfig';

class SEO extends Component {
    static propTypes = {
        subpageTittle: PropTypes.string,
        subpageDescription: PropTypes.string,
        subpagePath: PropTypes.string,
        isSubpage: PropTypes.bool,
    };

    static defaultProps = {
        subpageTittle: config.siteTitle,
        subpageDescription: config.siteDescription,
        subpagePath: 'PropTypes.string',
        isSubpage: false,
    };

    render() {
        const {
            subpageTittle,
            subpageDescription,
            subpagePath,
            isSubpage
        } = this.props;

        let title;
        let description;
        let image;
        let postURL;
        if (isSubpage) {
            title = (subpageTittle !== '' && subpageTittle != null)
                ? `${subpageTittle} - ${config.siteTitle}`
                : config.siteTitle;
            description = subpageDescription;
            postURL = urljoin(config.siteUrl, subpagePath);
        } else {
            title = config.siteTitle;
            description = config.siteDescription;
        }

        image = urljoin(config.siteUrl,  config.siteLogo);
        const homeUrl = urljoin(config.siteUrl);
        const schemaOrgJSONLD = [
            {
                '@context': 'http://schema.org',
                '@type': 'WebSite',
                url: homeUrl,
                name: title,
            }
        ];
        // if (isSubpage) {
        //     schemaOrgJSONLD.push(
        //         {
        //             '@context': 'http://schema.org',
        //             '@type': 'BreadcrumbList',
        //             itemListElement: [
        //                 {
        //                     '@type': 'ListItem',
        //                     position: 1,
        //                     item: {
        //                         '@id': postURL,
        //                         name: title,
        //                         image
        //                     }
        //                 }
        //             ]
        //         },
        //         {
        //             '@context': 'http://schema.org',
        //             '@type': 'BlogPosting',
        //             url: homeUrl,
        //             name: title,
        //             headline: title,
        //             image: {
        //                 '@type': 'ImageObject',
        //                 url: image
        //             },
        //             description
        //         }
        //     );
        // }
        return (
            <Helmet>
                {/* General tags */}
                <html lang="pl" />
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />
                <meta name="image" content={image} />

                {/* Schema.org tags */}
                <script type="application/ld+json">
                    {JSON.stringify(schemaOrgJSONLD)}
                </script>

                {/* OpenGraph tags */}
                <meta property="og:url" content={isSubpage ? postURL : homeUrl} />
                {isSubpage ? <meta property="og:type" content="article" /> : null}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />


                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                {/*<meta*/}
                    {/*name="twitter:creator"*/}
                    {/*content={config.userTwitter ? config.userTwitter : ''}*/}
                {/*/>*/}
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
            </Helmet>
        );
    }
}

export default SEO;
